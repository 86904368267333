@import "~include-media/dist/include-media";
@import '../../styles/common';

.wrapper {
  margin-bottom: rem(legacy-rem(30));
  height: rem(legacy-rem(800));
  overflow: hidden;
  position: relative;
  z-index: 1;

  @media (min-width: map_get($breakpoints, md)) {
    height: auto;
  }

  @include media("<lg") {
    margin: rem(50) 0 rem(70);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: map_get($breakpoints, lg)) {
    height: rem(legacy-rem(1400));
  }

  @media (min-width: map_get($breakpoints, xl)) {
    height: rem(legacy-rem(1500));
  }

  @media (min-width: 1920px) {
    height: rem(legacy-rem(1500));
  }

  @media (min-width: 2200px) {
    height: rem(legacy-rem(1500));
  }

  @media (min-width: 2500px) {
    height: rem(legacy-rem(1350));
    margin-top: rem(legacy-rem(-35));
  }

  @media (min-width: 3000px) {
    height: rem(legacy-rem(1600));
  }

  @media (min-width: 3500px) {
    height: rem(legacy-rem(1750));
  }

  @include media(">=xxxxxl") {
    height: rem(legacy-rem(2066));
  }

  .teamCard {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: map_get($breakpoints, lg)) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      justify-content: flex-end;
    }
  }
}

.teams-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.teamCard {
  grid-template-columns: 1fr;
  height: 100%;
  padding: 0 rem($mobile-container-gutter-width * .5);

  @include media(">=md") {
    padding: 0 rem($grid-gutter-width-medium * .5);
  }

  @include media(">=lg") {
    grid-template-columns: repeat(2, 1fr);
  }

  &__link {
    line-height: rem(legacy-rem(40));
    margin-top: rem(legacy-rem(90));

    @include media("<xl") {
      line-height: normal;
      margin: 0;
    }
  }

  a {

    text-decoration: underline;
    text-underline-position: under;
    transition: all .5s ease-in-out;
    color: $secondary-darkgrey2;

    @include media("<lg") {
      display: flex;
      justify-content: center;
    }

    &:hover {
      text-decoration-color: $brand-pink;
    }
  }
}

.teamCard-panel {
  max-width: rem(legacy-rem(500));

  @include media(">=lg") {
    margin-right: rem(0);
    margin-top: rem(200);
  }

  @include media(">=xl") {
    margin-right: rem(-40);
  }

  &__heading {
    @include media(">=xl") {
      margin-bottom: rem(60);
      margin-top: rem(150);
    }
  }

  &__heading * {
    font-family: $headerFont;
    line-height: rem(30);
    font-size: rem(22);
    margin-bottom: rem(30);
    padding: 0 30px;

    @include media(">=md") {
      line-height: rem(40);
      font-size: rem(30);
    }

    @include media(">=lg") {
      padding: 0;
    }

    @include media(">=xl") {

      font-size: rem(legacy-rem(45));
      line-height: rem(legacy-rem(60));
    }
  }

  &__content * {
    @include media("<lg") {
      font-size: rem(15);
      line-height: rem(22);
      margin-bottom: rem(25);
      text-align: center;
    }

    p {
      @include media("<lg") {
        margin-bottom: rem(25);
      }
    }
  }

  &__link {
    line-height: rem(legacy-rem(40));
  }
}
