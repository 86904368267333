@import "~include-media/dist/include-media";
@import '../../../styles/common';

.mainPath {
  fill: none;
  opacity: 0.7;
  stroke: #ff6274;
  stroke-miterlimit: 10;
  stroke-width: 3;
  visibility: hidden;
  stroke-linecap: round;

  @include media("<sm") {
    stroke-width: 6;
  }
}

.motionDiv {
  width: 100%;
  height: 100%;
}

.svgViewportWrapper {
  display: block;
  height: 100%;
  width: 100%;

  @include media(">=lg") {
    position: absolute;
  }
}

.svgViewport {
  display: block;
  width: auto;

  @include media("<lg") {
    margin-top: -15vh;
    margin-left: rem(legacy-rem(-40));
    min-width: calc(100vw + #{rem(legacy-rem(40))});
    width: 260%;
  }

  @include media("<md") {
    margin-top: -12vh;
    margin-left: rem(legacy-rem(-40));
    min-width: calc(100vw + #{rem(legacy-rem(40))});
    width: 260%;
  }

  @include media("<sm") {
    margin-top: -9vh;
    margin-left: rem(legacy-rem(-70));
    min-width: calc(100vw + #{rem(legacy-rem(120))});
    width: 282vw;
  }

  @include media("<xs") {
    margin-top: -8vh;
    margin-left: rem(legacy-rem(-75));
    min-width: calc(100vw + #{rem(legacy-rem(90))});
    width: 295vw;
  }

  @include media(">=lg") {
    position: absolute;
    z-index: -1;
    left: -#{rem(legacy-rem(80))};
    min-width: calc(100vw + #{rem(legacy-rem(40))});
    width: 155vw;
  }

  @media (min-width: 2200px) {
    width: 130vw;
    left: 0;
  }

  @media (min-width: 2500px) {
    width: 160vw;
    left: -#{rem(legacy-rem(750))};
  }

  @media (min-width: 3000px) {
    left: -#{rem(legacy-rem(993))};

  }
  @include media(">=xxxxxl") {
    width: 172vw;
    left: -#{rem(legacy-rem(1300))};
  }
}

.randomizeButton {
  background-color: #fff;
  border: 1px solid #333;
  cursor: pointer;
  margin: 10px;
  padding: 5px 10px;
}

.svgPoint {
  fill: orange;
}

.bubbleAnimatable {
  opacity: 0;
}
